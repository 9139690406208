<template>
  <div class="blog-category-container" v-loading="isLoading">
    <h2>文章分类</h2>
    <RightList :list="list" @select="handleSelect" />
  </div>
</template>

<script>
import RightList from "./RightList";
import { getBlogCategorise } from "@/api/blog.js";
export default {
  components: {
    RightList,
  },
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  computed: {
    limit() {
      return +this.$route.query.limit || 10;
    },
    categoryId() {
      return +this.$route.params.categoryId || -1;
    },
    list() {
      const totalArticleCount = this.data.reduce(
        (a, b) => a + b.articleCount,
        0
      );
      const result = [
        { name: "全部", id: -1, articleCount: totalArticleCount },
        ...this.data,
      ];
      return result.map((it) => ({
        ...it,
        isSelect: it.id === this.categoryId,
        aside: it.articleCount + "篇",
      }));
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.data = await getBlogCategorise();
      this.isLoading = false;
    },
    handleSelect(it) {
      const query = {
        page: 1,
        limit: this.limit,
      };
      if (it.id === -1) {
        this.$router.push({
          name: "Blog",
          query,
        });
      } else {
        this.$router.push({
          name: "CategoryBlog",
          query,
          params: {
            categoryId: it.id,
          },
        });
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
.blog-category-container {
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  overflow-y: auto;
  h2 {
    margin: 0;
    letter-spacing: 2px;
    font-size: 1em;
    margin: 0;
  }
}
</style>