import request from './request'

//分页获取博客
export const getBlogs = (page = 1, limit = 10, categoryid = -1) => request.get('/api/blog', { params: { page, limit, categoryid } })

//获取博客分类
export const getBlogCategorise = () => request.get('/api/blogtype')

//获取单篇博客
export const getBlog = (id) => request.get(`/api/blog/${id}`)

//提交评论
/* body: {
    nickname: "昵称",
    content: "评论内容，纯文本",
    blogId: <id>	#评论的博客id
} */
export const postComment = (commentInfo) => request.post('/api/comment', commentInfo)

// 分页获取评论
// page limit blogid keyword
export const getComments = (blogid, page = 1, limit = 10,) => request.get('/api/comment', {
    params: {
        blogid, page, limit
    }
})