<template>
  <div class="pager-container" v-if="pageNumber > 1">
    <a @click="handleClick(1)" :class="{ disabled: current == 1 }">|&lt;&lt;</a>
    <a @click="handleClick(current - 1)" :class="{ disabled: current == 1 }"
      >&lt;&lt;</a
    >
    <a
      @click="handleClick(n)"
      :class="{ active: current === n }"
      v-for="(n, i) in numbersArr"
      :key="i"
      >{{ n }}</a
    >
    <a
      @click="handleClick(current + 1)"
      :class="{ disabled: current === pageNumber }"
      >&gt;&gt;</a
    >
    <a
      @click="handleClick(pageNumber)"
      :class="{ disabled: current === pageNumber }"
      >&gt;&gt;|</a
    >
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    visibleNumber: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    pageNumber() {
      return Math.ceil(this.total / this.limit);
    },
    visbleMin() {
      let min = this.current - Math.floor(this.visibleNumber / 2);
      if (min < 1) {
        min = 1;
      }
      return min;
    },
    visbleMax() {
      let max = this.visbleMin + this.visibleNumber - 1;
      if (max > this.pageNumber) {
        max = this.pageNumber;
      }
      return max;
    },
    numbersArr() {
      let nums = [];
      for (let i = this.visbleMin; i <= this.visbleMax; i++) {
        nums.push(i);
      }
      return nums;
    },
  },
  methods: {
    handleClick(newPage) {
      if (newPage < 1) {
        newPage = 1;
      }
      if (newPage > this.pageNumber) {
        newPage = this.pageNumber;
      }
      if (newPage === this.current) {
        return;
      }
      this.$emit("pageChange", newPage);
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "~@/styles/var.less";
.pager-container {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  a {
    color: @primary;
    margin: 0 6px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: @lightWords;
    }
    &.active {
      color: @words;
      cursor: text;
      font-weight: 700;
    }
  }
}
</style>